import { Theme, withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"

export const ColoredButton = (backColor?: string, hoverColor?: string) => {
    if (backColor !== undefined) {
        if (hoverColor === undefined) {
            hoverColor = backColor
        }

        return (
            withStyles((theme: Theme) => ({
                root: {
                    color: theme.palette.getContrastText(backColor),
                    backgroundColor: backColor,
                    "&:hover": {
                        backgroundColor: hoverColor,
                    },
                },
            }))(Button)
        )
    }

    return Button
}
import { EntryForm } from "../components/EntryForm"
import { PageLayout } from "../components/PageLayout/PageLayout"

export function HomePage() {
    return (
        <PageLayout title="Добавить событие">
            <EntryForm />
        </PageLayout>
    )
}

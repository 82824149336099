import { createContext, useContext } from "react"
import { RootStore } from "../stores/RootStore"

export const RootStoreContext = createContext<RootStore | undefined>(undefined)

export function useRootStore() {
    const rootStore = useContext(RootStoreContext)
    if (rootStore === undefined) {
        throw new Error("useRootStore hook must be used within a RootStoreContext.Provider")
    }

    return rootStore
}
import { ErrorBoundary, Loading } from "@react-force/core"
import React, { Suspense } from "react"
import { RouterContext, RouterView } from "mobx-state-router"
import { CssBaseline } from "@material-ui/core"
import { RootStoreContext } from "./contexts"
import { initApp } from "./initApp"
import { viewMap } from "./stores/viewMap"

const rootStore = initApp()

function App() {
    return (
        <ErrorBoundary>
            <Suspense fallback={<Loading />}>
                <RootStoreContext.Provider value={rootStore}>
                    <RouterContext.Provider value={rootStore.routerStore}>
                        <CssBaseline />
                        <RouterView viewMap={viewMap} />
                    </RouterContext.Provider>
                </RootStoreContext.Provider>
            </Suspense>
        </ErrorBoundary>
    )
}

export default App

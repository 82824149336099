export const routes = [
    {
        name: "home",
        pattern: "/",
    },
    {
        name: "notFound",
        pattern: "/404",
    }
]

import { Header, HeaderTitle, NarrowContainer, ScrollingContainer, ViewVerticalContainer } from "@react-force/core"
import React, { ReactNode } from "react"

interface IProps {
    title: ReactNode
    narrow?: boolean
    children: ReactNode | ReactNode[]
}

export function PageLayout(props: IProps) {
    const { title, narrow, children } = props
    let content = children
    if (narrow === undefined || narrow) {
        content = (
            <NarrowContainer>
                {children}
            </NarrowContainer>
        )
    }

    return (
        <ViewVerticalContainer>
            <Header>
                <HeaderTitle>
                    {title}
                </HeaderTitle>
            </Header>

            <ScrollingContainer>
                {content}
            </ScrollingContainer>
        </ViewVerticalContainer>
    )
}

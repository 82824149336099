import { ColoredButton } from "./ColoredButton"
import React from "react"

interface IProps {
    text: string
    colors?: [string, string]
    onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
}

function EventButton(props: IProps) {
    const [backColor, hoverColor] = props.colors || []
    const Button = ColoredButton(backColor, hoverColor)
    return (
        <Button
            variant="contained"
            style={{ margin: "0.4em" }}
            onClick={props.onClick}
        >
            {props.text}
        </Button>
    )
}

export { EventButton }

import "date-fns"
import {
    KeyboardDatePicker,
    KeyboardTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers"
import React, { useState } from "react"
import {
    amber,
    blue,
    brown,
    cyan,
    green,
    grey,
    lightBlue,
    lightGreen,
    orange,
    pink,
    purple,
    red,
    teal,
    yellow,
} from "@material-ui/core/colors"
import DateFnsUtils from "@date-io/date-fns"
import { EventButton } from "./EventButton"
import Grid from "@material-ui/core/Grid"
import { NarrowContainer } from "@react-force/core"
import { useRootStore } from "../contexts"

function EntryForm() {
    const { routerStore } = useRootStore()
    const [selectedDate, setSelectedDate] = useState<Date | null>(
        new Date()
    )

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date)
    }

    const hanle404 = () => routerStore.goTo("notFound")

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <NarrowContainer>
                <Grid container={true} justify="space-around">
                    <div>
                        <KeyboardDatePicker
                            label="Дата"
                            fullWidth={true}
                            variant="dialog"
                            margin="normal"
                            format="yyyy-MM-dd"
                            value={selectedDate}
                            style={{ marginRight: "1em" }}
                            onChange={handleDateChange}>
                        </KeyboardDatePicker>
                        <KeyboardTimePicker
                            label="Время"
                            fullWidth={true}
                            variant="dialog"
                            format="HH:mm:ss"
                            className="MuiFormControl-marginNormal"
                            value={selectedDate}
                            onChange={handleDateChange}>
                        </KeyboardTimePicker>
                    </div>
                </Grid>
                <Grid container={true} justify="space-around">
                    <EventButton text="Подъем" colors={[ blue[300], blue[500] ]} onClick={hanle404} />
                    <EventButton text="Туалет" colors={[ teal[300], teal[700] ]} />
                    <EventButton text="Еда" colors={[ cyan[200], cyan[500] ]} />
                    <EventButton text="Работа" colors={[ green[300], green[500] ]} />
                    <EventButton text="Чтение" colors={[ amber[400], amber[700] ]} />
                    <EventButton text="Развлечение" colors={[ orange[400], orange[700] ]} />
                    <EventButton text="Гимнастика" colors={[ pink[200], pink[500] ]} />
                    <EventButton text="Вес" colors={[ red[200], red[500] ]} />
                    <EventButton text="Отдых" colors={[ teal[200], teal[500] ]} />
                    <EventButton text="Помощь" colors={[ purple[200], purple[500] ]} />
                    <EventButton text="Хозяйство" colors={[ purple[300], purple[600] ]} />
                    <EventButton text="Недомогание" colors={[ yellow[400], yellow[600] ]} />
                    <EventButton text="Мигрень" colors={[ brown[400], brown[600] ]} />
                    <EventButton text="Лечение" colors={[ red[300], red[600] ]}  />
                    <EventButton text="Учеба" colors={[ lightGreen[300], lightGreen[600] ]} />
                    <EventButton text="Хобби" colors={[ lightBlue[300], lightBlue[600] ]} />
                    <EventButton text="Прогулка" colors={[ grey[600], grey[800] ]} />
                    <EventButton text="Сон" colors={[ lightBlue[200], lightBlue[500] ]} />
                </Grid>
            </NarrowContainer>
        </MuiPickersUtilsProvider>
    )
}

export {
    EntryForm
}
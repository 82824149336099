import { RouterStore, createRouterState } from "mobx-state-router"
import { CallStatusStore } from "./CallStatusStore"
import { routes } from "./routes"

const notFound = createRouterState("notFound")

export class RootStore {
    callStatusStore = new CallStatusStore(this)
    routerStore = new RouterStore(routes, notFound, {
        rootStore: this
    })
}
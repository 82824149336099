import { HistoryAdapter, browserHistory } from "mobx-state-router"
import { RootStore } from "./stores/RootStore"
import { configure } from "mobx"

function initMobX() {
    configure({
        enforceActions: "observed",
    })
}

export function initApp() {
    initMobX()

    const rootStore = new RootStore()
    const { routerStore } = rootStore

    // sync router store with browser history
    const historyAdapter = new HistoryAdapter(routerStore, browserHistory)
    historyAdapter.observeRouterStateChanges()

    return rootStore
}
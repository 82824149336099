import { Link } from "@material-ui/core"
import { PageLayout } from "../components/PageLayout"
import React from "react"
import { useRootStore } from "../contexts"

export function NotFoundPage() {
    const { routerStore } = useRootStore()
    const goHome = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault()
        routerStore.goTo("home")
    }

    return (
        <PageLayout title="Нет такой страницы">
            <div style={{ margin: "1em" }}>
                <Link
                    component="a"
                    href="/"
                    onClick={goHome}
                >
                    Перейти в начало!
                </Link>
            </div>
        </PageLayout>
    )
}
import { RootStore } from "./RootStore"
import { makeAutoObservable } from "mobx"

export class CallStatusStore {
    rootStore: RootStore

    constructor (rootStore: RootStore) {
        makeAutoObservable(this)
        this.rootStore = rootStore
    }
}